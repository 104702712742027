<template>
  <a-modal
    :visible="visible"
    :title="$t('common.validate')"
    :okText="$t('common.continue')"
    :cancelText="$t('common.backtrack')"
    @ok="handleOK"
    @cancel="close"
  >
    {{
      $t(
        "warehouse.do_you_want_to_continue_with_products_with_unselected_labels"
      )
    }}?
    <a-collapse v-model:activeKey="data.activeKey" class="mt-2">
      <a-collapse-panel key="1" :header="$t('warehouse.product_info')">
        <div style="max-height: 300px; overflow-y: auto">
          <p v-for="(item, index) in noLabelSkuData" :key="index">
            {{ $t("warehouse.box") }}{{ item.box }} :
            <span v-for="(s, i) in item.skus" :key="i">
              {{ s }} <span v-if="i + 1 < item.skus.length">、</span>
            </span>
          </p>
        </div>
      </a-collapse-panel>
    </a-collapse>
  </a-modal>
</template>
<script>
import { reactive } from "vue";
import { Row, Col, Button, Modal, Collapse } from "ant-design-vue";

export default {
  name: "ConfirmLabel",
  components: {
    ARow: Row,
    ACol: Col,
    AButton: Button,
    AModal: Modal,
    ACollapse: Collapse,
    ACollapsePanel: Collapse.Panel,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    noLabelSkuData: {
      required: true,
    },
  },
  emits: ["update:visible", "OK"],
  setup(props, { emit }) {

    const data = reactive({
      loading: false,
      activeKey: null,
    });

    const handleOK = async (record) => {
      emit("OK");
      close();
    };

    const close = () => {
      emit("update:visible", false);
    };

    return {
      data,
      handleOK,
      close,
    };
  },
};
</script>

<style lang="less" scoped></style>
