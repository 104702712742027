<template>
  <a-modal
    width="1400px"
    :visible="visible"
    :centered="true"
    :maskClosable="false"
    @cancel="close"
    :footer="null"
    :title="$t('warehouse.add_box_scheme')"
  >
    <a-spin :spinning="addBoxModal.loading">
      <div >
        <a-row :gutter="[16, 16]" class="mb-2">
          <!-- <a-col>
          <SearchProduct ref="refSearchProduct"></SearchProduct>
        </a-col> -->
          <a-col>
            <a-button @click="handleShowAddProductModal">{{
              $t("warehouse.add_product")
            }}</a-button>
          </a-col>
        </a-row>
        <a-table
          :columns="columns"
          :data-source="addBoxModal.productList"
          :scroll="{ x: 500, y: 250 }"
          :pagination="false"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
          size="small"
        >
          <template #productInfo="{ record }">
            <a-space>
              <div class="table-list-img-common">
                <c-image :src="record.imgUrl"></c-image>
              </div>
              <div>
                <div>{{ record.productName }}</div>
                <div>{{ record.productNo }}</div>
              </div>
            </a-space>
          </template>

          <template #product_size="{ record }">
            <span v-if="record?.packingLength">
              {{ record?.packingLength }}x{{ record?.packingWidth }}x{{
                record?.packingHeight
              }}
              cm
            </span>
            <span v-else>-</span>
          </template>

          <template #weight="{ record }">
            <span v-if="record?.packingWeight">
              {{ gToKg(record?.packingWeight) }} kg
            </span>
            <span v-else>-</span>
          </template>

          <template #current_warehouse_usable_inventory="{ record }">
            <span
              :class="!record?.count || record?.count <= 0 ? 'text-error' : ''"
            >
              {{ record?.count }} Unit
            </span>
          </template>

          <template #encasement_count="{ record }">
            <a-input-number
              :disabled="!record?.count || record?.count <= 0"
              v-model:value="record.num"
              :min="1"
              :precision="0"
              :max="record?.count * 1"
            ></a-input-number>
            Unit
          </template>

          <template #volume="{ record }">
            <span v-if="record?.packingVolume">
              {{ cmCubicToM(record?.packingVolume) }} m<sup>3</sup>
            </span>
            <span v-else>-</span>
          </template>

          <template #product_label="{ record }">
            <a-select
              :disabled="!record?.count || record?.count <= 0"
              @change="handleChangeRowLabel"
              :loading="record.loading"
              :defaultValue="0"
              v-model:value="record.selectedLabelId"
              :showSearch="true"
              style="width: 150px"
              optionFilterProp="search-key"
            >
              <a-select-option
                :value="0"
                :currentLabels="null"
                :record="record"
              >
                {{ $t("warehouse.not_select_service") }}
              </a-select-option>
              <a-select-option
                v-for="(value, key) in record.labels"
                :key="key"
                :value="value.id"
                :currentLabels="value"
                :record="record"
                :search-key="value.name"
                :title="value.name"
              >
                {{ value.name }}
              </a-select-option>
            </a-select>
            <a-button
              type="link"
              size="large"
              @click.stop="handleShowAddProductLabel(record)"
            >
              <UploadOutlined />
            </a-button>
            <CProductLabelUpload
              v-model:visible="record.labelUploadVisible"
              :product="record.product"
              @confirm="(e) => handleUploadSuccessful(e, record)"
            />
          </template>

          <template #extra_tags="{ record }">
            <div class="mt-2 mb-2">
              <UploadExtraTag 
              v-model:fileUrl="record.otherLabelUrl"
                                  accept=".jpeg,.jpg,.png,.pdf"
              ></UploadExtraTag>
            </div>
          </template>

          <template #buttonCustom="{ record }">
            <a-button @click="handleRemove(record)">{{
              $t("common.remove")
            }}</a-button>
          </template>
        </a-table>
        
      <div class="mt-3 mb-2">
        {{$t('logistics.pack_material')}}:
        <a-radio-group v-model:value="addBoxModal.isSelectMaterialType"
            @change="handleNewGetPackingMaterial"
         >
          <a-radio :value="true"
            :disabled="!addBoxModal.isVerifiable"
          >{{ $t("warehouse.verify_available_packaging_materials") }}
          </a-radio>
          <a-radio :value="false" 
          :disabled="!addBoxModal.isVerifiable || !isSupportNotSelect"
          >
          {{ $t("warehouse.do_not_choose_packaging_materials") }}
          </a-radio>
        </a-radio-group>
      </div>
        <div v-if="addBoxModal.isCalculated">
          <strong>
            {{ $t("warehouse.select_packaging_box") }}
          </strong>
          <a-table
            :columns="materialColumns"
            :data-source="addBoxModal.materialList"
            :scroll="{ x: 500, y: 200 }"
            :pagination="true"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
            size="small"
          >
            <template #materialsType="{ record }">
              {{ record.materialTypeCnName }}/{{ record.materialTypeEnName }}
            </template>

            <template #outerDiameterSize="{ record }">
              <span v-if="!record.packMaterialLength">-</span>
              <span v-else>
                {{ record.packMaterialLength }}x{{
                  record.packMaterialWidth
                }}x{{ record.packMaterialHeight }}
                cm
              </span>
            </template>

            <template #weight="{ record }">
              <span v-if="record.packMaterialWeight">
                {{ gToKg(record.packMaterialWeight) }} kg
              </span>
              <span v-else> - </span>
            </template>

            <template #price="{ record }">
              {{ record.currencySymbol }}
              {{ $filters.amountToFixed2(record.price) }}
            </template>

            <template #totalWeight="{ record }">
              <span v-if="record.isSupported && record.totalWeightPack">
                <span v-if="record.totalWeightPack">
                  {{ gToKg(record.totalWeightPack) }} kg
                </span>
                <span v-else>-</span>
              </span>
              <span v-else>
                {{ $t("warehouse.not_applicable") }}
              </span>
            </template>

            <template #spaceUtilization="{ record }">
              <span v-if="record.isSupported">
                <span v-if="record.spaceUtilization">
                  {{ record.spaceUtilization }}%
                </span>
                <span v-else>-</span>
              </span>
              <span v-else>{{ $t("warehouse.not_applicable") }}</span>
            </template>

            <template #operateCustom="{ record }">
              <div v-if="record.isSupported">
                <a-button @click="handleSelectMaterial(record)">
                  <span v-if="record.isSelected">{{
                    $t("common.selected")
                  }}</span>
                  <span v-else>{{ $t("common.select") }}</span>
                </a-button>
              </div>

              <a-button v-else :disabled="!record.isSupported">
                {{ $t("warehouse.not_applicable") }}
              </a-button>
            </template>

            <!-- <template #footer>
              <a-row type="flex" justify="space-around" align="middle">
                <a-col>
                  <CPager
                    @handlePage="handlePageMaterial"
                    :showLessItems="true"
                    :pageSizeOptions="['5', '10', '30']"
                    :page-data="addBoxModal.pageData"
                  ></CPager>
                </a-col>
              </a-row>
            </template> -->
          </a-table>
         
        </div>
      </div>
    </a-spin>
     <div >
            {{
              $t("warehouse.set_the_number_of_boxes_according_to_this_plan")
            }}:
            <a-select
              :disabled="addBoxModal.isSelectMaterialType && !addBoxModal.selectMaterial
              || addBoxModal.isSelectMaterialType === null
              "
              :defaultValue="1"
              v-model:value="addBoxModal.boxCount"
              :showSearch="true"
              style="width: 150px"
              optionFilterProp="search-key"
            >
              <a-select-option
                v-for="(option, index) in 100"
                :key="index"
                :value="option"
                :search-key="option"
              >
                {{ option }}
              </a-select-option>
            </a-select>
          </div>
    <a-row type="flex" justify="center" align="middle" class="mt-1">
      <a-col>
        <a-button
          :disabled="(addBoxModal.isSelectMaterialType && !addBoxModal.selectMaterial )
          || addBoxModal.isSelectMaterialType === null"
          type="primary"
          @click="handleCreateBoxInfo"
        >
          {{ $t("warehouse.create_box_info") }}
        </a-button>
      </a-col>
    </a-row>
  </a-modal>

  <AddProductModal
    v-model:visibleProductModal="addProductModal.visibleProductModal"
    :warehouseId="warehouseId"
    :productIds="addProductModal.productIds"
    @addProducts="handleAddProduct"
  >
  </AddProductModal>
</template>

<script>
import { reactive, ref, watch, computed } from "vue";
import {
  Row,
  Col,
  Steps,
  Select,
  Table,
  Input,
  InputNumber,
  Button,
  Form,
  Modal,
  Space,
  Spin,
  Radio
} from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import CPager from "../../../components/CPager.vue";
import {
  getInventoryProductList,
  getMaterialsListByMergeProduct,
} from "../../../../api/modules/consignment/allot";
import CImage from "../../../components/CImage.vue";
import { gToKg, cmCubicToM } from "../../../../utils/general";
import SearchProduct from "../../../components/SearchProduct.vue";
import AddProductModal from "../components/AddProductModal.vue";
import CProductLabelUpload from "../../../components/CProductLabelUpload.vue";
import UploadExtraTag from "./UploadExtraTag.vue";

export default {
  name: "AddBoxModal",
  components: {
    UploadExtraTag,
    AddProductModal,
    CProductLabelUpload,
    CImage,
    CPager,
    SearchProduct,
    ASteps: Steps,
    AStep: Steps.Step,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputNumber: InputNumber,
    AButton: Button,
    AFormItem: Form.Item,
    AForm: Form,
    AModal: Modal,
    ASpace: Space,
    ASelect: Select,
    ASelectOption: Select.Option,
    ASpin: Spin,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    warehouseId: {
      required: true,
    },
  },
  emits: ["update:visible", "createBoxInfo"],
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });
    const refSearchProduct = ref();

    const columns = [
      {
        title: () => vueI18n.t("warehouse.serial_number"),
        width: 50,
        customRender: ({ text, index }) => {
          return index + 1;
        },
      },
      {
        title: "SESKU",
        dataIndex: "seSku",
        width: 90,
      },
      {
        title: () =>
          vueI18n.t("warehouse.product_name") +
          "/" +
          vueI18n.t("common.product_no"),
        slots: {
          customRender: "productInfo",
        },
        width: 130,
      },
      {
        title: () => vueI18n.t("warehouse.product_size"),
        slots: {
          customRender: "product_size",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.weight"),
        slots: {
          customRender: "weight",
        },
        width: 70,
      },
      {
        title: () => vueI18n.t("warehouse.current_warehouse_usable_inventory"),
        slots: {
          customRender: "current_warehouse_usable_inventory",
        },
        width: 120,
      },
      {
        title: () => vueI18n.t("warehouse.encasement_count"),
        slots: {
          customRender: "encasement_count",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.volume"),
        slots: {
          customRender: "volume",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.product_label"),
        slots: {
          customRender: "product_label",
        },
        width: 200,
      },
      {
        title: () => vueI18n.t("warehouse.extra_tags"),
        slots: {
          customRender: "extra_tags",
        },
        width: 100,
      },
      {
        title: "",
        slots: {
          customRender: "buttonCustom",
        },
        width: 80,
      },
    ];

    //包装材料
    const materialColumns = [
      {
        title: () => vueI18n.t("warehouse.packing_material_name"),
        dataIndex: "packMaterialName",
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.materials"),
        slots: {
          customRender: "materialsType",
        },
      },
      {
        title: () => vueI18n.t("warehouse.outer_diameter_size"),
        slots: {
          customRender: "outerDiameterSize",
        },
        width: 150,
      },
      {
        title: () => vueI18n.t("warehouse.weight"),
        slots: {
          customRender: "weight",
        },
      },
      {
        title: () => vueI18n.t("warehouse.material_fee"),
        slots: {
          customRender: "price",
        },
      },
      {
        title: () => vueI18n.t("warehouse.total_weight_after_packing"),
        slots: {
          customRender: "totalWeight",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.space_utilization"),
        dataIndex: "spaceUtilization",
        slots: {
          customRender: "spaceUtilization",
        },
        width: 100,
      },
      {
        title: "",
        slots: {
          customRender: "operateCustom",
        },
      },
    ];

    const addBoxModal = reactive({
      loading: false,
      isSelectMaterialType:null,
      isVerifiable: false,
      isCalculated: false,
      selectMaterial: null,
      productList: [],
      materialList: [],
      boxCount: 1,
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 5,
      },
    });

    watch(()=>props.visible,(newV,oldV)=>{
      if (newV) {
        addBoxModal.isSelectMaterialType = null;
      }
    })

    watch(
      () =>({
        length:addBoxModal?.productList.length,
        count:addBoxModal?.productList?.map(item=>item.num)
      }),
      (newV,oldV) => {
        if (newV.length !== oldV.length) {  
          _handleChangeProduct();
        }  
        if (newV.count.some((num, index) => num !== oldV.count[index])) { 
          _handleChangeProduct();
        }  
      },
      {deep: true }
    );

    const isSupportNotSelect = computed(() => {
        return  addBoxModal?.productList?.length == 1 &&
        addBoxModal?.productList[0]?.isOptionalPacking == true &&
        addBoxModal?.productList[0]?.num == 1
    });


    //改变了产品:重置材料选择 和 计算是否显示按钮"验算可用的包装材料"
    const _handleChangeProduct=()=>{
      addBoxModal.isSelectMaterialType=null;
      addBoxModal.materialList = [];
        addBoxModal.selectMaterial = null;
        addBoxModal.isCalculated = false;
        if (addBoxModal?.productList?.length == 0) {
          return (addBoxModal.isVerifiable = false);
        }

        let isNumberNull =
          addBoxModal.productList?.findIndex((x) => !x.num || x.num == 0) >= 0;
        addBoxModal.isVerifiable = !isNumberNull;
    }

    //----------------------更新产品标签 start------------------------------------------------
    const handleChangeRowLabel = (value, option) => {
      option.record.label = option.currentLabels;
    };

    const handleShowAddProductLabel = (record) => {
      record.product = {
        productId: record.productId,
        productName: record.productName,
        productNo: record.productNo,
        seSku: record.seSku,
      };
      record.labelUploadVisible = true;
    };

    const handleUploadSuccessful = (e, record) => {
      record.loading = true;
      const searchData = {
        warehouseId: props.warehouseId,
        productId: e.productId,
        skipCount: 0,
        maxResultCount: 1,
        needNoneInventory: true,
        isLabel: true,
      };
      getInventoryProductList(searchData)
        .then(({ result }) => {
          if (
            result.items &&
            result.items?.length == 1 &&
            result.items[0]?.productId == e.productId
          ) {
            record.labels = result.items[0]?.labels;
          }
        })
        .finally(() => {
          record.loading = false;
        });
    };

    //------------------------更新产品标签 end----------------------------------------------

    //------------------------包装材料操作 end----------------------------------------------

    // const handlePageMaterial = async (pageData) => {
    //   addBoxModal.pageData.skipCount = pageData.skipCount;
    //   addBoxModal.pageData.maxResultCount = pageData.maxResultCount;
    //   handleGetPackingMaterial();
    // };

    const handleNewGetPackingMaterial=()=>{
      if (addBoxModal.isSelectMaterialType) {
        // addBoxModal.pageData.skipCount = 0;
        // addBoxModal.pageData.maxResultCount = 5;
        // addBoxModal.pageData.currentIndex = 1;
        handleGetPackingMaterial();
      } else {
        addBoxModal.materialList = [];
        addBoxModal.selectMaterial = null;
        addBoxModal.isCalculated = false;
      }
    }

    const handleGetPackingMaterial = () => {
      let productData = addBoxModal.productList?.map((x) => {
        return {
          productId: x.productId,
          num: x.num,
        };
      });

      addBoxModal.loading = true;
      getMaterialsListByMergeProduct({
        warehouseId: props.warehouseId,
        productData,
        ...addBoxModal.pageData,
      })
        .then(({ result }) => {
          addBoxModal.materialList = result?.items ?? [];
          if (addBoxModal.selectMaterial) {
            let temp = addBoxModal.materialList?.find(
              (x) => x.id == addBoxModal.selectMaterial?.id
            );
            temp ? (temp.isSelected = true) : "";
          }
          addBoxModal.pageData.totalCount = parseInt(result?.totalCount);
          addBoxModal.isCalculated = true;
        })
        .catch((w) => {
          addBoxModal.materialList = [];
          addBoxModal.pageData.totalCount = 0;
        })
        .finally(() => {
          addBoxModal.loading = false;
        });
    };

    const handleSelectMaterial = (record) => {
      if (record.isSelected) {
        record.isSelected = false;
        addBoxModal.selectMaterial = null;
      } else {
        record.isSelected = true;
        if (addBoxModal.selectMaterial) {
          addBoxModal.selectMaterial.isSelected = false;
        }
        addBoxModal.selectMaterial = record;
      }
    };

    //------------------------包装材料操作 end----------------------------------------------

    //------------------------添加产品modal start-------------------------------------------
    const addProductModal = reactive({
      visibleProductModal: false,
      productIds: [],
    });

    const handleShowAddProductModal = () => {
      if (addBoxModal.productList && addBoxModal.productList?.length > 0) {
        addProductModal.productIds = addBoxModal.productList?.map((x) => {
          return x.productId;
        });
      }else{
        addProductModal.productIds=[];
      }
      addProductModal.visibleProductModal = true;
    };

    const handleAddProduct = (addList) => {
      if (addList && addList.length > 0) {
        addBoxModal.productList = [...addBoxModal.productList, ...addList];
      }
    };

    //------------------------添加产品modal end-------------------------------------------

    const handleCreateBoxInfo = () => {
      emit(
        "createBoxInfo",
        addBoxModal.productList,
        addBoxModal.selectMaterial,
        addBoxModal.boxCount
      );

      addBoxModal.productList = [];
      addBoxModal.selectMaterial = null;
      addBoxModal.materialList = [];
      addBoxModal.isCalculated = false;
      addBoxModal.boxCount = 1;
      emit("update:visible", false);
    };

    const handleRemove = (record) => {
      addBoxModal.productList = addBoxModal.productList?.filter(
        (x) => x.productId != record.productId
      );
    };

    const close = () => {
      emit("update:visible", false);
    };

    return {
      refSearchProduct,
      columns,
      materialColumns,

      gToKg,
      cmCubicToM,

      addBoxModal,
      handleCreateBoxInfo,
      close,

      addProductModal,
      handleShowAddProductModal,

      handleAddProduct,
      handleRemove,

      isSupportNotSelect,

      // handlePageMaterial,
      handleNewGetPackingMaterial,
      handleGetPackingMaterial,
      handleSelectMaterial,

      handleShowAddProductLabel,
      handleUploadSuccessful,
      handleChangeRowLabel,
    };
  },
};
</script>

<style lang="less" scoped></style>
